import React from 'react'
import { Container } from 'react-bootstrap'

const PrivacyPolicy = () => {
  return (
    <>
      <Container>
        <div className="row">
          <div className="col-md-12 mt-5 mb-5">
            <div>
              <p className='privacytext'>
                PRIVACY POLICY <br /> Last Modified: October 24, 2022
              </p>
              <p className='privacytext mt-5'>
                CapFi Incorporated ("CapFi", “Varcity”, “we”, “us”, or “our”, as applicable) is committed to protecting the privacy of the personal information of our customers, all users of our website, www.varcity.io, and all its features, tools, services, accounts, subscription plans, or information (collectively, the “Website”), and any other stakeholders (“you” or “your”, as applicable). We value your trust and recognize that maintaining this trust requires us to be transparent and accountable in how we treat the information that you choose to share with us.
              </p>
            </div>

            <div>
              <h2 className='privacytext mt-5'> <b>PERSONAL INFORMATION</b></h2>
              <p className='privacytext'>
                “Personal Information” means information about an identifiable individual. Personal Information can include an individual’s opinions or beliefs, as well as facts about, or related to, the individual.

              </p>
              <p className='privacytext mt-5'>
                We may gather your Personal Information when you create an account with us and enroll in a subscription plan, use the Website, and when you contact us for any support or customer service. This privacy policy (the “Privacy Policy”) describes how we collect, use, disclose, and protect that Personal Information.

              </p>
              <p className='privacytext mt-5'>
                Certain types of publicly available Personal Information, such as business contact information, or names, addresses and telephone numbers as published in telephone directories, may not be subject to the same level of protection.

              </p>
            </div>


            <div>
              <h2 className='privacytext mt-5'> <b>CONSENT</b></h2>
              <p className='privacytext'>
                We typically directly ask for your consent to collect, use, or disclose your Personal Information. However, your consent also may be implied or implicit through your conduct when it is reasonable and legally permissible for us to do so. For example, your consent to this Privacy Policy may be implied by your use or interaction with our Website prior to creating an account with us. We will not require you to consent to the collection, use, or disclosure of your Personal Information beyond that which we require for our purposes.
              </p>
              <p className='privacytext mt-5'>
                We will honour any specific consents you provide to us. You may withdraw or change your consent at any time by giving us reasonable notice and subject to legal or contractual restrictions. In appropriate cases, we will inform you of any implications of withdrawing your consent. However, we reserve the right to collect, use, and disclose your Personal Information without your knowledge or consent where we are legally required or permitted to do so, for example, when investigating fraud or other contravention of law.
              </p>
            </div>

            <div>
              <h2 className='privacytext mt-5'> <b>INFORMATION WE COLLECT ABOUT YOU</b></h2>
              <p className='privacytext'>
                We may collect the following Personal Information from you:
              </p>
              <p className='privacytext'>
                •	your name, username, and contact information such as mailing address, e-mail address, and telephone number; <br />
                •	confirmation that you are you are at least 18 years old, or the age of majority as determined by the laws of your province or territory of residency; <br />
                •	your billing and account information; <br />
                •	any content you upload to the Website;<br />
                •	information regarding your visit to the Website;<br />
                •	information regarding your use of the Website and its features, tools, and services, including your stock simulation trading data; and<br />
                •	information regarding your interactions with us or our customer support team.<br />
              </p>
              <p className='privacytext mt-5'>
                We will limit the amount and type of Personal Information we collect to that which is necessary for our purposes, which are outlined in the How We Use Your Information section below.

              </p>
            </div>

            <div>
              <h2 className='privacytext mt-5'> <b>
                HOW WE USE YOUR INFORMATION</b></h2>
              <p className='privacytext'>
                We may use your Personal Information for the following purposes:
              </p>
              <p className='privacytext'>

                •	to verify your identity; <br />
                •	to create your account with us;<br />
                •	to manage your subscriptions;<br />
                •	to make our Website all its features, tools, and services, available to you;<br />
                •	to calculate your Varcity Trading IndexTM (VTI);<br />
                •	to improve our Website and all its features, tools, and services;<br />
                •	to provide you with information about us and our Website;<br />
                •	to send you information on special offers or promotions;<br />
                •	to respond to your inquiries or provide you with support;<br />
                •	to carry out our obligations and enforce our rights arising from any agreements with you;<br />
                •	to fulfill the purposes for which you provided the information or that were described when it was collected, or any other purpose for which you provide it;<br />
                •	to detect, prevent and suppress errors, fraud, financial abuse and other unauthorized or illegal activities;<br />
                •	to display targeted advertising on our Services, and on the websites of our third-party business partners and to measure or understand the effectiveness of messages we deliver to you; and<br />
                •	for any other purposes authorized or required by law.<br />
              </p>
              <p className='privacytext mt-5'>
                You may choose to unsubscribe from promotional emails we send to you at any time.
              </p>
            </div>

            <div>
              <h2 className='privacytext mt-5'> <b>
                HOW WE SHARE YOUR INFORMATION
              </b></h2>
              <p className='privacytext'>
                We may disclose or otherwise share your Personal Information to the following persons or in the following circumstances:
              </p>
              <p className='privacytext'>

                •	to our parent company, affiliates, subsidiaries, successors or assigns; <br />
                •	to contractors, service providers, and other third parties we use to support our Website (such as payment processors and search engine providers that assist us with website improvement and optimization);<br />
                •	to advertisers and advertising networks that require the information to select and serve relevant advertisements to you and others;<br />
                •	to comply with any court order, law, or legal process, including to respond to any government or regulatory request, in accordance with applicable law; and<br />
                •	as necessary to fulfill the purposes for which you provide the Personal Information.<br />

              </p>
            </div>

            <div>
              <h2 className='privacytext mt-5'> <b>
                COOKIES
              </b></h2>
              <p className='privacytext'>
                As you navigate through and interact with our Website, we may use cookies or other automatic data collection technologies to gather certain information about your visit, browsing actions, and patterns to distinguish you from other visitors, to measure the effectiveness of our Website and advertising, and personalize your visit to our Website.
                A cookie is a small file that is stored on your computer or mobile device by a website in order to remember information about your browsing history or your device when you return to that website. Some cookies are “session cookies” that are deleted at the end of your browsing session while some are “persistent cookies” that remain between sessions until they expire or you delete them. Similar tracking technologies are pixels, web beacons or other technologies that store or access information on your computer or device.
              </p>
              <p className='privacytext mt-5'>
                We may collect the following Personal Information about your visit to our Website: your Internet Protocol (IP) address and the general geographic location associated with your IP address (known as IP geolocation), information about your computer or mobile device such as your browser type, the full Uniform Resource Locators (URL) clickstream to, through and from our Website, the date, time and duration of your visit, whether you are a first time or returning visitor, content you viewed and searched, recorded images such as forms you populated, and your interests and demographics.
              </p>

              <p className='privacytext mt-5'>
                We use third party analytics, including Google Analytics, to create statistics and generate reports about visits to our Website. For information on how Google Analytics collects and processes your data, please see “How Google uses data when you use our partners’ sites and apps”. We may also use other analytics providers to create statistics, generate reports, or send you targeted advertisements.

              </p>
              <p className='privacytext mt-5'>
                You may disable cookies in your browser settings and clear your cache of previously installed cookies. However, if you disable cookies, our Website may not function properly and your customized use of our Website may be hindered. In addition to disabling cookies, you may also install the Google Analytics opt-out browser add-on, which prevents Google Analytics from collecting information about your website visits.
              </p>
            </div>

            <div>
              <h2 className='privacytext mt-5'> <b>
                THIRD PARTY ADVERTISING SERVICES
              </b></h2>
              <p className='privacytext'>
                We may use third party advertising services to deliver customized content and advertising to you and to measure the effectiveness or performance of our advertising campaigns. These third party advertising services may use cookies, alone or in conjunction with other device identifiers to serve you advertisements on sites across the Internet based upon your visit and use of our Website.

              </p>
              <p className='privacytext mt-5'>
                You may opt-out of several third party ad servers' and networks' cookies simultaneously by using an opt-out tool created by the Digital Advertising Alliance of Canada.
              </p>

              <p className='privacytext mt-5'>
                Please note opting out of a network does not mean you will no longer receive online advertising. You will continue to receive non-customized advertisements and you may continue to receive customized advertisements from parties that do not participate in the opt out programs.
              </p>
            </div>

            <div>
              <h2 className='privacytext mt-5'> <b>
                LIMITED RETENTION & DE-IDENTIFIED OR ANONYMIZED DATA
              </b></h2>
              <p className='privacytext'>
                Except as otherwise permitted or required by applicable law, we only retain your Personal Information for as long as necessary to fulfill the purposes described in How We Use Your Information. Under some circumstances, after the purposes of collection have been fulfilled, we may de-identify or anonymize your Personal Information so that it can no longer be associated with you. We reserve the right to use such de-identified or anonymized data for any purpose and in accordance with applicable law without further notice to you or your consent.

              </p>
            </div>

            <div>
              <h2 className='privacytext mt-5'> <b>
                SECURITY SAFEGUARDS
              </b></h2>
              <p className='privacytext'>
                The security of your Personal Information is very important to us. We use reasonable physical, technical, and administrative measures designed to secure your Personal Information from accidental loss and from unauthorized access, use, alteration, and disclosure.
              </p>
              <p className='privacytext mt-5'>
                We store your Personal Information in Canada, however, we may transfer your Personal Information outside of Canada for processing by third parties for some or all of the purposes described in How We Use Your Information. In such circumstances, we will use contractual and other means to ensure your Personal Information is protected while in the foreign jurisdiction. However, your Personal Information may be still accessible to law enforcement agencies, government agencies, courts, and national security authorities of the foreign jurisdiction. If you have questions about our policies and practices regarding service providers outside of Canada, please contact our Privacy Officer using the contact information at the end of this Privacy Policy.

              </p>
            </div>
            <div>
              <h2 className='privacytext mt-5'> <b>
                ACCURACY
              </b></h2>
              <p className='privacytext'>
                We make reasonable efforts to ensure that the Personal Information we have is accurate, complete and up-to-date as necessary for the purposes for which it is to be used, including Personal Information that is disclosed to third parties, and Personal Information that is used to make a decision about you.

              </p>
            </div>
            <div>
              <h2 className='privacytext mt-5'> <b>
                ACCESS & UPDATE TO YOUR INFORMATION
              </b></h2>
              <p className='privacytext'>
                You have a general right to access your Personal Information in our possession or custody. Upon receiving your written request, we will inform you of the existence, use, and disclosure of your Personal Information and give you access to your Personal Information. If we are not able to provide a list of the organizations to which we may have actually disclosed your Personal Information, we will provide you with a list of organizations to which we may have disclosed your information. We will respond to your written access request within a reasonable timeframe, which is generally 30 days, or we will provide you with an explanation if additional time is required to fulfill your request.
              </p>
            </div>
            <div>
              <h2 className='privacytext mt-5'> <b>
                CHALLENGING COMPLIANCE
              </b></h2>
              <p className='privacytext'>
                Upon your request, we will provide you with information about our procedures for addressing any complaints about our compliance with privacy laws. We will investigate all written complaints, and if we find a complaint to be justified, we will take any appropriate measures, including amending this Privacy Policy and our internal practices.
              </p>
            </div>
            <div>
              <h2 className='privacytext mt-5'> <b>
                CHANGES TO POLICY
              </b></h2>
              <p className='privacytext'>
                Our commitment to your privacy includes periodically reviewing our privacy policies and practices. Accordingly, we may change this Privacy Policy from time to time and the revised policy will be sent to you and/or posted to our Website effective as of the revised date stated on the revised policy. It is therefore important that you review this Privacy Policy, as it may be amended from time to time, regularly.
              </p>
            </div>

            <div>
              <h2 className='privacytext mt-5'> <b>
                CONTACT US
              </b></h2>
              <p className='privacytext'>
                If you have any questions or concerns regarding this Privacy Policy or our privacy practices, please contact our Privacy Officer at:
              </p>
            </div>

            <div className='mt-5'>
              <p className='privacytext'>
                CapFi Incorporated
              </p>
              <p className='privacytext'>
                600 Matheson Blvd W, Unit 5
              </p>
              <p className='privacytext'>
                Mississauga, ON L5R 4C1
              </p>
              <p className='privacytext'>
                info@capfii.com
              </p>
            </div>


          </div>
        </div>
      </Container>
    </>
  )
}

export default PrivacyPolicy
import React from 'react'
import { Accordion, Container } from 'react-bootstrap'
import DocumentMeta from 'react-document-meta'
import { Link } from 'react-router-dom'
import { varcitylogo } from '../assets'
import LightGreenBanner from '../components/varcityBanner/LightGreenVarcitybanner'

const Careers = () => {
    const meta = {
        title: 'Varcity Careers',
        description: 'Join our fast-paced, high-growth Canadian team!',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Varcity careers, Varcity jobs, remote jobs'
            }
        }
    };
    return (
        <>
            < DocumentMeta {...meta} />
            <Container fluid>
                <div className="row pb-5" style={{ background: "#C5F9CA", paddingTop: "100px", paddingBottom: "100px" }}>
                    <div className="col-1"></div>
                    <div className="col-md-5 ps-5 ps-sm-0 mb-5 pb-5 headerColumn">
                        <div style={{ marginTop: "80px" }}>
                            <h1 className='greyheaderHeading'>
                                Careers
                            </h1>

                            <p className='greyheaderSubTitle mt-3'>Varcity is a trading simulator with real-time market data that helps users develop great trading skills through quantitative trading rankings, rewards and recognition.
                            </p>
                            <p className='greyheaderSubTitle mt-3'>
                                We are a proudly Canadian company working towards transparency and accessibility in the capital markets, bound by the belief that <b> great traders are made.</b>
                            </p>
                        </div>
                    </div>

                </div>
                <div className="row" >
                    {/* <div className="col-6"></div> */}

                    <div className='col-md-12 headerLogo'>
                        <div className='careerHeaderImg'></div>
                    </div>
                </div>
            </Container>

           {/*} <Container>
                <div className="row">
                    <div className="col-md-12 mt-5 mb-4">
                        <h1 className='fs-42 fw-500 greyclr'>Job Openings</h1>
                    </div>

                    <div className="col-md-12 mt-5">
                        <Accordion defaultActiveKey={['0']} alwaysOpen className='pricingAndPaymentFaq'>
                            <Accordion.Item eventKey="0" style={{ borderTop: "2px solid #C5F9CA" }}>
                                <Accordion.Header>Social Media and Community Manager</Accordion.Header>
                                <Accordion.Body className='pricingAccordianbody'>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className='fs-16'> Help us grow Varcity’s audience and community!  Varcity is a fast paced, high-growth startup looking for a Social Media pro with specific knowledge in retail investing and retail trading.  Remote position, please see full job description
                                                <a href={SocialMediaPdf} download="Social_Media_Manager_Job_Description.pdf">
                                                    <span className='fw-500 greyclr' style={{ borderBottom: "1.5px solid #0B4550" }}><b> here.</b></span>
                                                </a>
                                            </p>
                                        </div>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                            {/* <Accordion.Item eventKey="1">
                                <Accordion.Header>Director, B2B Sales and Licensing</Accordion.Header>
                                <Accordion.Body className='pricingAccordianbody'>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className='fs-16'>
                                            </p>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item> 

                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Customer Support Specialists</Accordion.Header>
                                <Accordion.Body className='pricingAccordianbody'>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p className='fs-16'>Working with the Head of Customer Success, Varcity's Customer Support Specialists will play an important role in managing and resolving user product issues and feedback.  Remote position, please see full job description
                                                <a href={CustomerSucccessSpecialistPdf} download="Varcity.io_CustomerSupportSpecialist_JD.pdf">
                                                    <span className='fw-500 greyclr' style={{ borderBottom: "1.5px solid #0B4550" }}><b> here.</b>
                                                    </span>
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </div>
            </Container> */}

            <Container fluid className='mt-5 pt-lg-5'>
                <LightGreenBanner />
            </Container>
        </>
    )
}

export default Careers
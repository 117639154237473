import axios from "axios";

export const signUp = (data) => {
  const body = {
    login: data?.login,
    password: data?.password,
    verifyPassword: data?.password,
    ageConfirmation: 1,
    termsConditionsAcceptance: 1,
    lpCode:data?.lpCode
  };
  return axios.post(
    `${process.env.REACT_APP_BASE_URL}user2/signup-basic-user`,
    body
  );
};

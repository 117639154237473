import React from 'react'
import { Container } from 'react-bootstrap'

const TermCondition = () => {
    return (
        <>
            <Container>
                <div className="row">
                    <div className="col-md-12 mt-5 mb-5">
                        <h1 className='privacytext'>TERMS AND CONDITIONS</h1>
                        <div>
                            <p className='privacytext'>
                                This website, Varcity.io, and all its features, tools, services, accounts, subscription plans, or information (collectively, the “Website”), are owned and operated by CapFi Incorporated or its affiliates, subsidiaries, successors or assigns (collectively, "CapFi", “Varcity”, “we”, “us” or “our”). If you access or use this Website, then you accept and agree to be bound by these Terms & Conditions. If you do not agree to these Terms & Conditions, then you must not access or use the Website and you must immediately deactivate any account you have registered with us.
                            </p>

                            <p className='privacytext mt-5'>
                                You may contact us at any time by email at: info@capfii.com, by phone at: 1-365-398-5011, or by mail at: CapFi Incorporated, 600 Matheson Blvd W, Unit 5, Mississauga, ON L5R 4C1.
                            </p>
                            <p className='privacytext mt-5'>
                                THE WEBSITE IS A MARKET SIMULATION AND IS PROVIDED FOR ENTERTAINMENT, RECREATIONAL, AND EDUCATIONAL PURPOSES ONLY.
                            </p>
                            <p className='privacytext mt-5'>
                                THE WEBSITE DOES NOT OFFER, SOLICIT, OR ARRANGE FOR THE SALE OR PURCHASE OF SECURITIES AND NO ACTUAL SECURITIES ARE PURCHASED, SOLD OR TRADED AS PART OF THE WEBSITE.
                            </p>
                            <p className='privacytext mt-5'>
                                VARCITY DOES NOT OFFER ANY INVESTMENT ADVICE, GUIDANCE, OR RECOMMENDATIONS. VARCITY AND ITS DIRECTORS, OFFICERS, EMPLOYEES, AND CONTRACTORS ARE NOT REGISTERED INVESTMENT ADVISORS OR BROKERS AND DO NOT PURPORT TO TELL OR SUGGEST WHICH SECURITIES CUSTOMERS SHOULD BUY OR SELL WHEN USING THE WEBSITE OR OTHERWISE. EMPLOYEES AND/OR AFFILIATES OF VARCITY AND RELATED PARTIES OF EMPLOYEES AND/OR AFFILIATES OF VARCITY MAY HOLD POSITIONS IN THE STOCKS OR INDUSTRIES DISCUSSED OR FEATURED ON THE WEBSITE. YOU SHOULD NOT ASSUME THAT THE METHODS, TECHNIQUES, OR INDICATORS PRESENTED ON THE WEBSITE WILL BE PROFITABLE OR THAT THEY WILL NOT RESULT IN LOSSES WHEN USING THE WEBSITE OR OTHERWISE. HYPOTHETICAL PERFORMANCE RESULTS HAVE MANY INHERENT LIMITATIONS INCLUDING THAT THEY DO NOT INVOLVE FINANCIAL RISK AND THEREFORE CANNOT ACCOUNT FOR THE IMPACT OF FINANCIAL RISK IN ACTUAL TRADING. FOR EXAMPLE, THE ABILITY TO WITHSTAND LOSSES OR ADHERE TO A PARTICULAR TRADING STRATEGY IN SPITE OF TRADING LOSSES ARE MATERIAL AND CAN ADVERSELY AFFECT TRADING RESULTS. YOU UNDERSTAND AND ACKNOWLEDGE THAT THERE IS A VERY HIGH DEGREE OF RISK INVOLVED IN TRADING SECURITIES. ANY TRADING IN ACTUAL SECURITIES OR OTHER INVESTMENTS INVOLVES A RISK OF SUBSTANTIAL LOSSES AND SHOULD ONLY BE CONDUCTED ON THE ADVICE OF QUALIFIED INVESTMENT PROFESSIONALS. VARCITY WILL NOT BE LIABLE UNDER ANY CIRCUMSTANCES FOR ANY LOSS OR DAMAGE THAT YOU OR ANYONE ELSE SUSTAINS AS A RESULT OF ANY TRADING OR INVESTMENT ACTIVITY THAT YOU OR ANYONE ELSE ENGAGES IN AS A RESULT OF OR IN CONNECTION WITH YOUR USE OF THE WEBSITE OR ANY INFORMATION OR MATERIAL YOU RECEIVE THROUGH THE WEBSITE OR IN ANY OTHER CIRCUMSTANCES WHATSOEVER.
                            </p>

                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                1.	ELIGIBILITY
                            </p>
                            <p className='privacytext'>
                                You represent and warrant that you are at least 18 years old, or the age of majority as determined by the laws of your province or territory of residency, and have full legal capacity to assume the obligations set forth in these Terms & Conditions.
                            </p>

                            <p className='privacytext mt-5'>
                                You undertake to access and use the Website solely from the provinces or territories in which the Website is available. As of the date of these Terms & Conditions, the Website is not available to residents of the Province of Quebec and to the residents of the following prohibited countries:
                            </p>

                            <ul>
                                <li>Afghanistan</li>
                                <li>Belarus</li>
                                <li>Burundi</li>
                                <li>Central African Republic</li>
                                <li>China</li>
                                <li>Congo Republic</li>
                                <li>Cuba</li>
                                <li>Crimea</li>
                                <li>Eritrea</li>
                                <li>Guinea</li>
                                <li>Guinea-Bissau</li>
                                <li>Haiti</li>
                                <li>Iran</li>
                                <li>Iraq</li>
                                <li>Liberia</li>
                                <li>Libya</li>
                                <li>Moldova</li>
                                <li>Myanmar</li>
                                <li>Nicaragua</li>
                                <li>North Korea</li>
                                <li>Papua New Guinea</li>
                                <li>Russia</li>
                                <li>Somalia</li>
                                <li>South Sudan</li>
                                <li>Sri Lanka</li>
                                <li>Sudan</li>
                                <li>Syria</li>
                                <li>Ukraine</li>
                                <li>Vanuatu</li>
                                <li>Venezuela</li>
                                <li>Yemen</li>
                                <li>Zimbabwe</li>
                            </ul>
                            <p className='privacytext'>
                                You undertake to access and use the Website in accordance with all applicable laws and not for any illegal or unauthorized purpose.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                2.	SUBSCRIPTION FEES AND PAYMENT TERMS
                            </p>
                            <p className='privacytext'>
                                In order to access certain content and features of the Website, you must register as a user and enrol for a Basic or Pro subscription. Pro subscriptions include all benefits of a Basic subscription plus access to additional content and features such as Orderbook, My Portfolio, Trading Journal, Stop and Stop-Limit orders, If-Then and OCO orders, 3 Watchlists, 10 Alerts and more to give you a full suite of demo trading tools. Details of the content and features included in a Basic subscription and a Pro subscription are outlined on the Pricing page of the Website.
                            </p>

                            <p className='privacytext mt-5'>
                                You may enrol for a Basic subscription at no charge. The fees and payment schedule for the Pro subscription will be as communicated to you from time to time, and may change over time. You will be charged all applicable taxes on your purchase of any Pro subscription. All fees and taxes will be charged in USD. You can try the Pro subscription for seven (7) calendar days at no charge. If the Pro subscription is not cancelled by you before the end of the seven (7)-day trial period, it will automatically convert to a monthly subscription plan.
                            </p>

                            <p className='privacytext mt-5'>
                                In order to enrol for a Pro subscription, you will be required to provide us with a valid payment method. By providing any payment account information to us, you represent to us that you are an authorized user of such payment account.
                            </p>

                            <p className='privacytext mt-5'>
                                Pro subscriptions are billed in advance on a monthly or annual basis depending on your chosen plan. All Pro subscription plans will automatically renew for consecutive monthly or annual terms, as applicable, at the fees then in effect, until you cancel your subscription. Your payment method will be automatically charged at the time of renewal. If required by law, we will provide you with notice of your renewal or expiration of your subscription. If a valid payment method is not provided, it will be your responsibility to provide one at the time initial purchase or renewal in order to continue your subscription.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>

                                3.	YOUR CANCELLATION AND REFUND RIGHTS
                            </p>
                            <p className='privacytext'>
                                Registered users may cancel their Basic or Pro subscription at any time through their Varcity account or by contacting us at the contact information provided above. If you cancel your Pro subscription, your account will be automatically switched to a Basic subscription at the end of the billing cycle. You may deactivate your account at any time.
                            </p>

                            <p className='privacytext mt-5'>
                                Registered users who enrol for an annual Pro subscription plan may cancel their annual plan or any renewal of their annual plan within fourteen (14) calendar days, in which case we will cancel your annual plan effective immediately and provide you with a full refund. In all other circumstances, if you cancel your Pro subscription before the end of your current billing cycle, your Pro subscription will remain active until the next renewal date. We do not offer refunds or credits for partial months, or for months in which you have an active Pro subscription plan that is unused by you. We do not offer refunds for upgrades to a more expensive subscription plan or a longer billing cycle and instead, remaining days are converted into an equivalent value of days on the new subscription plan.

                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                4.	OUR SUSPENSION AND CANCELLATION RIGHTS
                            </p>
                            <p className='privacytext'>
                                We reserve the right to suspend or cancel your Basic or Pro subscription at any time for any reason in our sole discretion.
                            </p>

                            <p className='privacytext mt-5'>
                                If we cancel your subscription without cause, we will give you notice at least thirty (30) calendar days prior to the effective date of cancellation after which time your account will be deactivated, your subscription will terminate. If we choose to cancel your subscription, we will refund any amounts you have paid in advance for the subscription, for the period following the date of de-activation of your account and termination of the subscription.
                            </p>
                            <p className='privacytext mt-5'>
                                We may also cancel your subscription without prior notice to you for any good cause, including but not limited to, if you breach these Terms & Conditions, do not pay amounts that are due, interfere with our efforts to provide the Website, interfere with our business, or use our Website for illegal or improper purposes. You have no right to have the subscription reactivated, even if you cure any of these problems. Whether we allow you to have a subscription again will be entirely up to us. Subject to applicable laws, in the event we cancel your subscription for good cause, we may not refund any prepaid or unused portions of your subscription fees.
                            </p>
                            <p className='privacytext mt-5'>
                                We may suspend your subscription for any of the same reasons we may cancel it. We may also suspend your subscription for network or system maintenance or improvement, or if there is network congestion.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                5.	WEBSITE CONTENT
                            </p>
                            <p className='privacytext'>
                                The contents of the Website, including but not limited to, the logos, graphics, images, text, software and other material are owned by or licensed to Varcity, and may be covered by one or more copyrights, trademarks, service marks, patents, trade secrets or other legal protections. We hereby grant you a limited, revocable, non-transferable license to view and use the Website, and download or print a copy of the content received from the Website, for your personal, non-commercial use only, subject to the terms of these Terms & Conditions. You agree not to modify or remove any copyright, trademark, or other proprietary rights notice contained on the Website or use any content except as expressly authorized by these Terms & Conditions.

                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                6.	THIRD PARTY CONTENT
                            </p>
                            <p className='privacytext'>
                                Certain content, products, and services available on the Website may include materials from third parties including but not limited to the display of third party advertising and/or links to third party websites (collectively, the “Third Party Content”). Varcity is not responsible for reviewing and evaluating any Third Party Content and will not be responsible to you for any Third Party Content. Varcity does not, directly or indirectly, approve or endorse the Third Party Content. If you use the Website to view or access Third Party Content, you do so at your own risk.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                7.	USER CONTENT
                            </p>
                            <p className='privacytext'>
                                You are solely responsible for content that you submit, display, or publish on the Website, or any information or material that you transmit to us or other registered users of the Website (collectively, “User Content”). You agree that Varcity may review, edit or delete any User Content that you publicly display on the Website, which Varcity believes, in its sole discretion, breaches these Terms & Conditions or which may be offensive, illegal, or violate the rights, harm, or threaten the safety or security of anyone else. You hereby grant to Varcity an irrevocable, perpetual, worldwide license to use, copy, display, and distribute the User Content that you publicly display on the Website and create derivative works therefrom, and you represent and warrant to Varcity that you have the proper authority to grant such license.
                            </p>
                            <p className='privacytext'>
                                You agree that unsolicited ideas or suggestions that you transmit to Varcity will become Varcity’s property and that Varcity may use those ideas or suggestions in any manner, at any time, and for any reason, it so chooses with no obligation to acknowledge you or pay you any form of compensation.

                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                8.	YOUR RESPONSIBILITIES
                            </p>
                            <p className='privacytext'>
                                In order to use the Website and all its features, you must have a computer, laptop, or device capable of connecting to the internet, and a working internet connection.

                            </p>
                            <p className='privacytext mt-5'>
                                Registered users of the Website will be required to sign in with a username and password. You agree to be fully responsible for the protection of your username and password. Anyone who has access to your username and password may be able to access your Varcity account and its features. We have no responsibility for or obligation to inquire about the authority of anyone using your username or password or other information that can be used to identify your account.
                            </p>
                            <p className='privacytext mt-5'>
                                You agree not to use, or allow others to use, the Website for any fraudulent, unlawful, or abusive purpose, or in any way that is not described in materials provided by us to you or that interferes with our provision of the Website to you or to our other registered users. You agree not to abuse or do anything to damage the Website or our business operations, reputation, employees, or facilities, including but not limited to, modifying, adapting, translating or reverse-engineering any portion of the Website or its features, or uploading or transmitting viruses or any other type of malicious code that may be used in any way to affect the functionality or operation of the Website or any of its features.

                            </p>
                            <p className='privacytext mt-5'>
                                You agree not to use the Website in any manner, including but not limited to use of any of the Website’s features or aspects or any photos or reproductions of the Website or its content, to provide or purport to provide investment advice in any form. You agree to comply with all applicable securities laws in connection with your use of the Website and not to use the Website if you are or if such use would constitute noncompliance with applicable securities laws.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                9.	PERSONAL INFORMATION
                            </p>
                            <p className='privacytext'>
                                Varcity’s collection, use, and disclosure of your personal information is governed by our Privacy Policy, which is hereby incorporated by reference into these Terms & Conditions.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                10.	CHANGES TO THESE TERMS & CONDITIONS
                            </p>
                            <p className='privacytext'>
                                We reserve the right to amend each of the sections of these Terms & Conditions at any time including the subscription fees, the Website features, your rights and obligations, and our rights and obligations. We will send you, at least 30 days before the amendment comes into force (or such longer period as required by applicable law), a written notice drawn up clearly and legibly, setting out the new clause only, or the amended clause and the clause as it read formerly, the date of the coming into force of the amendment, and your cancellation rights. You may refuse the amendment and cancel your subscription without cost or penalty but after payment of the amounts owed for your subscription up to the date of cancellation. If you elect not to cancel your subscription after receiving our notice of a change, your continued subscription will constitute acceptance of the amended Terms & Conditions.

                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                11.	DISCLAIMER OF WARRANTIES
                            </p>
                            <p className='privacytext'>
                                CONSUMER PROTECTION LAWS IN SOME JURISDICTIONS DO NOT ALLOW FOR THE LIMITATIONS OR EXCLUSIONS OF LEGAL WARRANTIES. IF THESE LAWS APPLY TO YOU, THE FOLLOWING EXCLUSIONS OR LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
                            </p>
                            <p className='privacytext mt-5'>
                                Except as expressly provided herein, Varcity makes no representation, warranty, or condition, express or implied, regarding the Website or the information contained on the Website, which is provided to you on an “as is” and “where is” basis. We cannot promise or guarantee that you will be able to access the Website whenever or wherever you desire or that the Website will be uninterrupted, problem-free, or error-free. There may be periods of time when you cannot access the Website for various reasons including heavy player traffic on the Website. We will not make any corrections or adjustments for trades, attempted trades, or missed trades affected by the Website’s operations or downtime.
                            </p>
                            <p className='privacytext mt-5'>
                                Varcity hereby expressly disclaims any and all warranties of any kind, express or implied, about the Website including but not limited to, any warranties as to content, quality, accuracy, timeliness, completeness, correctness, reliability, non-infringement, merchantability, or fitness for a particular purpose. All such warranties are expressly excluded by these Terms & Conditions.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                12.	LIMITATIONS OF LIABILITY
                            </p>
                            <p className='privacytext'>
                                CONSUMER PROTECTION LAWS IN SOME JURISDICTIONS DO NOT ALLOW FOR THE LIMITATIONS OR EXCLUSIONS OF CERTAIN LIABILITIES. IF THESE LAWS APPLY TO YOU, ANY OR ALL OF THE EXCLUSIONS OR LIMITATIONS IN THIS SECTION MAY NOT APPLY TO YOU.
                            </p>
                            <p className='privacytext'>
                                We are not liable to you for the following: (1) Website errors, defects, problems, failures, or interruptions; (2) our non-performance caused in whole or in part by an act or omission of a third party or equipment failure; (3) our collection, use, or disclosure of your personal information or other data; (4) another person’s authorized or unauthorized use of your subscription or account with us; or (5) any act of god, natural disaster, strike or other labour issue, equipment or facility shortage, or any other cause beyond our control.
                            </p>
                            <p className='privacytext'>
                                Except in connection with indemnification claims as provided in these Terms & Conditions, neither you nor we can recover punitive, exemplary, consequential, indirect, incidental or special damages (including but not limited to loss of profits or earnings, loss of business opportunity, loss of data or property, or any other loss whatsoever, arising directly or indirectly, from your subscription or your use of the Website) or lawyers’ fees.
                            </p>
                            <p className='privacytext'>
                                Our maximum aggregate liability to you under any theory of liability (including but not limited to breach of contract, misrepresentation, personal injury is limited to the amount paid by you for a subscription plan during the twelve (12) month period preceding the date that the claim arose. You agree that we would not have agreed to provide the Website to you if you did not agree to this limitation. This amount is our sole and exclusive liability to you.
                            </p>
                            <p className='privacytext'>
                                You agree that the limitations of liability in these Terms & Conditions apply to you and also to any user of your account, to anyone making a claim on your behalf, and to any claims made by your family or others arising out of or relating to your subscription or your use of the Website.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                13.	INDEMNITY
                            </p>
                            <p className='privacytext'>
                                Subject to any other or greater rights that you may have under applicable law, you agree that you will indemnify, defend, and hold harmless Varcity and its parent company and affiliates, and their respective directors, officers, employees, contractors, and agents, from and against any and all claims, demands, actions, losses, damages, death, or injury, costs and expenses (including reasonable lawyers’ fees), arising out of or in connection with (1) your registered account with us; (2) your Basic or Pro subscription plan; (3) your access to or use of the Website or any of its features; (4) your failure or inability to access or use the Website or any of its features; (5) your provision of your personal information or other data to us (including but not limited to your payment account information); (5) your breach of these Terms & Conditions or the activities contemplated by these Terms & Conditions; or (6) any alleged infringement with respect to User Content.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                14.	NOTICES
                            </p>
                            <p className='privacytext'>
                                Any written notice from you required by these Terms & Conditions will be considered given when we receive it at the following address: CapFi Incorporated, 600 Matheson Blvd W, Unit 5, Mississauga, ON L5R 4C1.
                            </p>
                            <p className='privacytext mt-5'>
                                Any written notice from us required by these Terms & Conditions will be considered given when we send it by email to any email address you have provided to us, or two (2) calendar days after we mail it to you at the most current mailing address we have on file for you.
                            </p>
                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                15.	OUR RELATIONSHIP
                            </p>
                            <p className='privacytext'>
                                These Terms & Conditions do not create any fiduciary relationships between you and us. It also does not create any relationship of principal and agent, partnership, or employer and employee.
                            </p>

                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                16.	ASSIGNMENT
                            </p>
                            <p className='privacytext'>
                                Varcity may assign these Terms & Conditions or your obligations to pay under it in whole or in part to anyone we choose. You cannot assign these Terms & Conditions or your obligations to anyone else without our prior written consent and any attempted assignment in violation of this provision shall be void.
                            </p>

                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                17.	WAIVER
                            </p>
                            <p className='privacytext'>
                                No waiver of any part of these Terms & Conditions or of any breach of them in any one instance will require us to waive any other instance or breach.
                            </p>

                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                18.	VALIDITY
                            </p>
                            <p className='privacytext'>
                                If any part or provision of these Terms & Conditions is considered invalid or unenforceable by a court of law or arbitrator, the remaining parts or provisions will remain valid and enforceable.
                            </p>

                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                19.	ENTIRE AGREEMENT
                            </p>
                            <p className='privacytext'>
                                These Terms & Conditions, the Privacy Policy, and any other document incorporated herein by reference, constitute the entire agreement between you and us. It supersedes all other agreements between us, past or present. In the event of a conflict between any provision contained in these Terms & Conditions and any provision of any other document incorporated herein, the provision contained in these Terms & Conditions shall take precedence, and no additional or different terms shall be binding on either of us unless mutually agreed to in writing.
                            </p>

                        </div>
                        <div>
                            <p className='privacytext mt-5'>
                                20.	SURVIVAL
                            </p>
                            <p className='privacytext'>
                                You agree that the disclaimers, limitations of liability, and indemnities in these Terms & Conditions will survive even after your subscription has ended or you have ceased accessing or using our Website. Even after our relationship has ended, these Terms & Conditions will govern any disputes arising out of or relating to them unless they have been replaced by a new agreement between us.
                            </p>

                        </div>

                        <div>
                            <p className='privacytext mt-5'>
                                21.	GOVERNING LAW
                            </p>
                            <p className='privacytext'>
                                These Terms & Conditions shall be governed by the laws of the province or territory in which you reside.
                            </p>

                        </div>



                    </div>
                </div>
            </Container>
        </>
    )
}

export default TermCondition
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { tickCircleAsset } from "../../assets";
import SaveButton from "../../components/Button/Button";
import { SIGNUP_URL } from "../../config/ApiEndPoints";

const SignUp = () => {
  const [data, setData] = useState([]);
  const [validateEmail, setValidateEmail] = useState([]);
  const [loading, setLoading] = useState();

  const onSubmit = () => {
    console.log("button clicked");
    validationHandler();
  };

  const validationHandler = () => {
    let regex = /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/;
    if (data.trim().match(regex)) {
      saveData();
      setValidateEmail("");
    } else {
      setValidateEmail("Email is invalid");
      return false;
    }
  };
  const spinner = document.getElementById("spinner");
  const saveData = async () => {
    spinner.removeAttribute("hidden");
    // console.log(`url: ${SIGNUP_URL}`);

    const response = await fetch(`${SIGNUP_URL}` + data.trim(), {});
    spinner.setAttribute("hidden", "");

    const responseData = await response.json();
    console.log(`responseData: ${responseData.success}`);
    responseData.success && setData("");
    responseData.success && setLoading(responseData?.message);
  };

  // useEffect(() => {
  //   saveData();
  // }, []);
  return (
    <>
      <form>
        <div className="d-flex gap-2 align-items-center mt-4">
          <p className="fs-16 fw-300 greyclr "> Email:</p>
          <div>
            <input
              type="text"
              className="input_email ps-2 pe-2"
              value={data}
              onChange={(e) => {
                setData(e.target.value);
              }}
              placeholder="Enter a Email"
            />
            <p className="fs-14 fw-700 text-danger">{validateEmail}</p>
          </div>
        </div>

        <div className="d-flex gap-2 align-items-center mt-4">
          <SaveButton
            label="Sign up"
            buttonStyle="supportSignUp"
            onClick={onSubmit}
          />
          <div hidden id="spinner"></div>

          {loading && data == "" ? <img src={tickCircleAsset} alt="" /> : null}
        </div>
      </form>
    </>
  );
};

export default SignUp;

import React from 'react'
import { Container } from 'react-bootstrap'
import Button from '../components/Button/Button'
import SignUp from '../components/signUpComponent/SignUp'
const Support = () => {
    return (
        <>
            <Container>
                <div className="row">
                    <div className="col-md-12 mt-5 mb-5">
                        <h2 className='fs-16 fw-700 greyclr'><b>KNOWLEDGE BASE -  IN CLOSED BETA</b></h2>

                        <p className='fs-16 fw-300 greyclr mt-5'>
                            Find everything you need to know about Varcity through our in-depth Knowledge Base. At Varcity we think that great traders are made, which is why we want you to have the tools to make that happen! In our Knowledge Base you will learn everything there is to know. Starting with creating your account and your first login to updating your billing information, changing your account type and more perks that come with your Basic and Pro account.
                        </p>
                        <p className='fs-16 fw-300 greyclr mt-5'>
                            Use your account to its full potential and read about the following topics:
                        </p>
                        <ul className='varcityNetworkPoints'>
                            <li> Using your Trading Dashboard</li>
                            <li>Our new-to-industry VTI ranking</li>
                            <li>Placing trades, reading charts, trading tools and more!</li>
                            <li>Your Trading Journal</li>
                            <li>The Leaderboard and your badges / trophy case</li>
                        </ul>


                        <p className='fs-16 fw-300 greyclr'>
                            Something missing or want to see more in our Knowledge Base? Email support@varcity.io with your feedback!
                        </p>
                        <p className='fs-16 fw-300 greyclr mt-5'>
                            Our Knowledge Base will come online at launch and is temporarily restricted during closed beta.  Join our waitlist below and we will let you know when we’re ready to launch!  Until then, we’ll be posting updates on our social channels.
                        </p>

                        {/* <p className='fs-16 fw-300 greyclr mt-5'> Email</p>

                        <Button label="Sign up" buttonStyle="supportSignUp mt-4 mb-5" /> */}

                        <SignUp />
                    </div>
                </div>



            </Container >
        </>
    )
}

export default Support
import React from 'react'
import "./style.css"
import Button from '../components/Button/Button'
import { Container } from 'react-bootstrap'
import { affiliateAsset, affiliateProgramAsset, blank542Asset, blank554Asset, blankImg, home1Asset, home2Asset, home3Asset, home4Asset, LearnText, platformLiceningAsset, RankText, TradeText, varcitydarklogo, varcitylogo, varcityNetwork } from '../assets'
import { Link } from 'react-router-dom'
import DarkGreenVarcityBanner from '../components/varcityBanner/DarkGreenVarcityBanner'
import DocumentMeta from 'react-document-meta'

const Platform = () => {
  const meta = {
    title: 'Varcity Features',
    description: ' Get your free Varcity Trading Index™ performance rankimg to see how your demo trading skills stack up on our network for Forex, Crypto, Equities, Indices and Futures.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'trading simulator, varcity trading index, learn to trade, forward testing, forex, futures, indices, equities, crypto'
      }
    }
  };
  return (
    <>
      < DocumentMeta {...meta} />
      <div className="container-fluid">
        <div className="row pb-5" style={{ background: "#C5F9CA" }}>

          <div className="col-1"></div>
          <div className="col-md-5  mb-5 headerColumn">
            <div style={{ marginTop: "80px" }}>
              <h1 className='greyheaderHeading'>
                Great traders are  <span style={{ fontStyle: "italic" }}>made.</span>
              </h1>

              <p className='greyheaderSubTitle mt-5'>Varcity is a trading simulator with real-time market data that helps users develop great trading skills through forward testing, quantitative performance metrics, amd rankings.
              </p>
              <p className='greyheaderSubTitle mt-4'> Test out your trading strategies with our enterprise-grade trading tools and our free Varcity Trading Index<sup className='fs-8 smSup'>TM</sup> performance ranking. Over 350 instruments available across Equities, Forex, Crypto, Indices and Futures!</p>
            </div>
          </div>

        </div>
        <div className="row" >

          <div className="col-6"></div>

          <div className='col-md-12 headerLogo'>
            <div className='platformHeaderImg'></div>
          </div>
        </div>

      </div>
      <Container>
        <div className="row mt-sm-5" style={{ marginTop: "100px" }}>
          <div className="col-md-6" >
            {/* <img src={home1Asset} width="100%" height="450px" className='height450' alt="" /> */}
            <img src={TradeText} width="100%" height="454px" className='platformLiceningImg' alt="" />
          </div>
          <div className="col-md-6 mt-sm-3">
            <h1 className='fs-42 fw-500 greyclr mt-2 mb-2'>Fully automated trading simulator with <span style={{ fontStyle: "italic" }}>real-time data.</span></h1>

            <p className='fs-16 fw-500 greyclr mt-3'>
              We’ve taken the traditional paper trading philosophy a step further:  we have added crowdsourced performance metrics to show how your demo trading skills and strategies stack up to everyone else on our network.

            </p>
            <p className='fs-16 fw-500 greyclr mt-3'>
              Varcity’s  trading platform has the same features and functionality as live trading platforms, even though we only use simulated virtual funds.  Demo trading is done on our platform directly without third-party apps such as MT4/MT5, all with real-time data feeds.

            </p>
            <p className='fs-16 fw-500 greyclr mt-3'> Varcity’s trading platform is available both in a web-based desktop experience and as a mobile app.  Visit our
              <a href="https://sites.google.com/varcity.io/varcitysupport/home">
                <span className='greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b> Knowledge Base.</b></span>
              </a> for more information on platform capabilities.</p>

            <p className='fs-16 fw-500 greyclr mt-3'>  Currently, Varcity offers demo trading in Forex, Crypto, Indices, Equities and Futures.</p>
          </div>
        </div>
        <div className="row mt-sm-5" style={{ marginTop: "70px" }}>
          <div className="col-md-6">
            <h1 className='fs-42 fw-500 greyclr mt-lg-2 mt-md-2 mb-2'>Varcity Trading Index<sup className='fs-12 lgSup' style={{ top: "-17px" }}>TM</sup>real-time evaluation.</h1>

            <p className='fs-16 fw-500 greyclr mt-3'>
              The Varcity Trading Index<sup className='fs-8 smSup'>TM</sup> (VTI) evaluates your quantitative trading skills in real-time, on every closed position, ranking trading performance across our network.  The VTI combines standardized industry performance analytics with a proprietary algorithm that assesses trading behaviours across the Varcity network, assigning each user an overall rank of trading performance that is both absolute and relative to other users’ performance.

            </p>
            <p className='fs-16 fw-500 greyclr mt-3'>
              To acknowledge your successes and recognize your great trading skills, the VTI issues badges for events such as highest VTI, VTI by percentile, highest returns and other weekly/monthly performance metrics.  Check the
              <a href="https://sites.google.com/varcity.io/varcitysupport/badge-list">
                <span className='greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b> Badge List </b></span>
              </a>
              for new badge drops, we are always adding new badges based on user feedback!
            </p>
            <p className='fs-16 fw-500 greyclr mt-3'>In Pro acounts, the VTI also displays additional performance analytics, all recorded in an automated trading journal. This allows you to historically track your trades and VTI rank with powerful analytics.  Read more about performance metrics, automated journal and the VTI in our
              <a href="https://sites.google.com/varcity.io/varcitysupport/home">
                <span className='greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b> Knowledge Base.</b></span>
              </a>
            </p>

            <p className='fs-16 fw-500 greyclr mt-3'>  Please note: the VTI is not predictive of performance in live trading and is for educational purposes only on Varcity's trading simulator.  Please see our
              <div className='mb-2'>
                <Link to="/termCondition">
                  <span className='greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}> <b>Terms and Conditions.</b></span>
                </Link>
              </div>
            </p>
          </div>
          <div className="col-md-6" >
            <img src={RankText} width="100%" height="554px" className='height300' alt="" />
          </div>

        </div>
        <div className="row mt-sm-5" style={{ marginTop: "70px" }}>
          <div className="col-md-6" >
            <img src={LearnText} width="100%" height="450px" alt="" />
          </div>
          <div className="col-md-6">
            <h1 className='fs-42 fw-500 greyclr mt-5 mb-2'>Varcity Network for mentorship and support.</h1>

            <p className='fs-16 fw-500 greyclr mt-4'>
              Your VTI, badges and trophy case are part of your Varcity Network profile. Access the network to:
            </p>
            <ul className='varcityNetworkPoints'>
              <li>Connect with top demo traders with high VTI rankings.</li>
              <li>  Find and learn with other demo traders with similar VTIs.</li>
              <li> Link and display your social accounts on the Varcity Network to expand your audience.</li>
            </ul>
          </div>
        </div>
        
      </Container>

      <div className="container-fluid mt-5">

        <DarkGreenVarcityBanner />


      </div>

      <Container>

      <div className="row mt-5 mb-5" >
          <div className="col-md-6">
            <h1 className='fs-42 fw-500 greyclr mt-2 mb-2'>Partnerships</h1>

            <p className='fs-16 fw-500 greyclr mt-3'>
            Varcity partners with brands and companies that help our users achieve their financial goals. We combine our trading simulator, VTI and quantitative performance rankings to create dynamic collaborations and opportunities.
            </p>
            <Link to="/partners">
              <div className='mt-4'>
                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b></span>
              </div>
            </Link>
          </div>
          <div className="col-md-6">
            <h1 className='fs-42 fw-500 greyclr mt-2 mb-2'>Varcity’s Referral Program</h1>

            <p className='fs-16 fw-500 greyclr mt-3'>
              Help spread our message of inclusivity, transparency and fairness for all those wanting to learn how to trade, on their path towards becoming a great trader.
            </p>
            <p className='fs-16 fw-500 greyclr mt-3'>
              For a limited time, Varcity's referral program offers 30% lifetime commissions on all Varcity Pro subscriptions purchased through your referrals. Your unique referral code is automatically generated and included in your Welcome email!
            </p>
            <a href="https://sites.google.com/varcity.io/varcitysupport/referral-program">
              <div className='mt-4'>
                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b></span>
              </div>
            </a>
          </div>
        </div>

      </Container>
    </>
  )
}

export default Platform
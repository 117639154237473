import "./App.css";
import Navbar from "./components/navbar/Navbar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Routes,
} from "react-router-dom";
import Home from "./pages/Home";
import LandingPage1 from "./pages/LandingPage1";
import Platform from "./pages/Platform";
import Pricing from "./pages/Pricing";
import Company from "./pages/Company";
import Partners from "./pages/Partners";
import Careers from "./pages/Careers";
import ContactUs from "./pages/ContactUs";
import Footer from "./components/footer/Footer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermCondition from "./pages/TermCondition";
import Support from "./pages/Support";
import TemporarySignUp from "./pages/TemporarySignUp";
import SignupSuccess from "./pages/SignupSuccess";
import CookieBanner from "./components/navbar/CookieBanner";
import VarcityCodeOfConduct from "./pages/VarcityCodeOfConduct";
import ScrollToTop from "./components/ScrollToTop";
import ReactGA from "react-ga4";
import React, { useEffect } from "react";

const Tracking_ID = "G-H9SRLWRR1W"; // Google tracking ID
ReactGA.initialize(Tracking_ID);

function App() {
  // Google analytics custom path
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "Page Visit",
    });
  }, []);

  return (
    <>
      <Router>
        <div>
          {/* <CookieBanner /> */}
          <Navbar />
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/learn-to-trade-for-free-1" element={<LandingPage1 />} sensitive/>
              <Route path="/platform" element={<Platform />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/company" element={<Company />} />
              <Route path="/partners" element={<Partners />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/contactUs" element={<ContactUs />} />
              <Route path="/support" element={<Support />} />
              <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="/termCondition" element={<TermCondition />} />
              <Route path="/temporarySignUp" element={<TemporarySignUp />} />
              <Route path="/codeofConduct" element={<VarcityCodeOfConduct />} />
              <Route path="/signupSuccess" element={<SignupSuccess />} />
            </Routes>
          </ScrollToTop>
          <Footer />
        </div>
      </Router>
    </>
  );
}

export default App;

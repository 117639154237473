import React from 'react'
import { Container } from 'react-bootstrap'

const VarcityCodeOfConduct = () => {

    return (
        <>
            <Container>
                <div className="row">
                    <div className="col-md-12 mt-5 mb-5 pt-4 pb-lg-5">
                        <h3 className='fs-16 fw-700 black'>
                            <b> Varcity’s Code of Conduct</b>
                        </h3>

                        <div>
                            <p className='privacytext mt-5'>
                                Varcity’s social media accounts including, but not limited to, Instagram, Facebook, Twitter, and Tik Tok, have been created so that our community can have a fun and upbeat area to openly discuss trading topics and the Varcity demo trading platform, as well as foster mentorship and learning. To ensure this is a safe environment for all those participating on our social media accounts, and by extension the Varcity Network, Varcity will not tolerate any of the following actions to take place on any of our communication platforms:
                            </p>
                            <p className='privacytext'>
                                Harassment, sexism, racism, homophobia, bigotry, hate speech/derogatory language will not be tolerated. Any users found to be participating in such will be removed from the platform.
                            </p>
                            <p className='privacytext'>
                                Profane or vulgar content will not be tolerated. This includes things such as media, text, profile pictures, and usernames using any profanity, offensive language or imagery, or other disturbing content.
                            </p>
                            <p className='privacytext'>
                                Refrain from addressing sensitive issues and avoid all controversial topics (including but not limited to political and religious views). Varcity Employees and Representatives reserve the right to determine appropriate comments.
                            </p>

                            <p className='privacytext mt-5'>
                                If a user is found to be behaving inappropriately or interacting on any of Varcity’s social media posts in a negative or offensive manner, Varcity reserves the right to delete and/or block said user.  Varcity Basic and Pro accounts may be further terminated for violating Varcity’s Code of Conduct and user will be deleted from the Varcity’s demo trading platform and the Varcity Network.

                            </p>
                            <p className='privacytext mt-5 mb-5'>
                                Respect between Varcity Employees/Representatives and other members of the community must remain civil and respectful. Should there be any concerns, please reach out to a Varcity at info@varcity.io for review. Varcity responds to messages and comments between 9AM-6PM EST, Monday - Friday, with a goal of responding to messages within 24 hours during this time period.
                            </p>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default VarcityCodeOfConduct
export const tradingAccountsPointForLandingPage1 = [
    'Real-time Level 1 quotes on 350+ instruments',
    'Simulated trading using Market and Limit orders on $100k virtual account',
    'Charting tools',
    'Watchlists and alerts',
    'Varcity Trading Index™ ranking + 3 trade performance metrics',
    'Varcity network profile with trophy case and social links',
    'Leaderboard and badging - climb the ranks!',
  ];
  export const tradingAccountsPointForLandingPage2 = [
    'Real-time Level 1 quotes on 350+ instruments',
    'Simulated trading using all order and conditional order type on $100k virtual account',
    'Charting tools',
    'Watchlists and alerts',
    'Varcity Trading Index™ ranking + 10 trade performance metrics',
    'Automated trading journal',
    'Portfolio at a glance',
    'Varcity network profile with trophy case and social links',
    'Leaderboard and badging - climb the ranks!',
  ];
  export const tradingAccountsPointForLandingPage3 = [
    'Real-time Level 1 quotes on 350+ instruments',
    'Simulated trading using all order and conditional order type on $100k virtual account',
    'Charting tools',
    'Watchlists and alerts',
    'Varcity Trading Index™ ranking + 10 trade performance metrics',
    'Automated trading journal',
    'Portfolio at a glance',
    'Varcity network profile with trophy case and social links',
    'Leaderboard and badging - climb the ranks!',
  ];
import React from 'react'
import { Container } from 'react-bootstrap'
import DocumentMeta from 'react-document-meta'
import { Link } from 'react-router-dom'
import { home2Asset, platformLiceningAsset, tickCircleAsset, varcitydarklogo } from '../assets'
import DarkGreenVarcityBanner from '../components/varcityBanner/DarkGreenVarcityBanner'
import LightGreenVarcitybanner from '../components/varcityBanner/LightGreenVarcitybanner'
import Button from '../components/Button/Button'

const Company = () => {
    const meta = {
        title: 'Varcity Company Info',
        description: 'Varcity was created with the belief that great traders are made.  Regardless of background or formal education, great trading skills can be learned.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Canadian fintech, trading simulator, CapFi, exchange softwware, cloud exchange'
            }
        }
    };
    return (
        <>
            < DocumentMeta {...meta} />
            <div className="container-fluid">
                <div className="row pb-5" style={{ background: "#C5F9CA" }}>
s
                    <div className="col-1"></div>
                    <div className="col-md-5 ps-5 ps-sm-0 mb-5 headerColumn">
                        <div style={{ marginTop: "80px" }}>
                            <h1 className='greyheaderHeading'>
                                About Varcity
                            </h1>

                            <p className='greyheaderSubTitle'>Varcity is a free trading simulator with real-time market data that helps traders at all levels develop great trading skills through quantitative trading metrics and rankings.
                            </p>
                            <p className='greyheaderSubTitle mt-4'> Varcity is built using CapFi's multi-asset exchange software on the CapFi Cloud.
                             </p>
                        </div>
                        <div className='d-flex gap-4 mt-5 sm-justify-content-center'>
                            <a href="https://capfii.com/" style={{zIndex:10}}>
                                <Button label="   Learn more about CapFi   " buttonStyle="capfi" />
                            </a>
                        </div>

                    </div>

                </div>
                <div className="row" >
                    <div className='col-md-12 headerLogo'>
                        <div className='companyHeaderImg'></div>
                    </div>
                </div>

            </div>

            {/*<Container>
                <div className="row mt-5 pt-5">
                    <div className="col-md-6 mt-5">
                        <div className='d-flex align-items-center gap-2'>
                            <img src={tickCircleAsset} alt="" width="35px" height="35px" />
                            <div>
                                <h2 className='fs-42 fw-500 greyclr'>Great traders are made.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-5">
                        <p className='fs-16 fw-300 greyclr'> Anyone, anywhere, can become a great investor with the right tools.  We believe great traders are made and Varcity helps facilitate a path towards financial literacy through:</p>

                        <ul className='varcityNetworkPoints mt-4'>
                            <li> Free subscriptions to our enterprise-grade trading simulator for educational purposes, with real-time data, analytics, and learning resources.</li>
                            <li> Access to the Varcity Trading Index<sup className='fs-8 smSup'>TM</sup> (VTI), a powerful evaluation tool that assesses real-time demo trading performance.</li>
                        </ul>
                    </div>

                    {/*<div className="col-md-6 mt-5">
                        <div className='d-flex align-items-center gap-2'>
                            <img src={tickCircleAsset} alt="" width="35px" height="35px" />
                            <div>
                                <h2 className='fs-42 fw-500 greyclr'>Built by CapFi.</h2>
                            </div>
                        </div>
                    </div>*/}
                    {/*<div className="col-md-6 mt-5">
                        <p className='fs-16 fw-300 greyclr mt-3'>To ensure an inclusive and transparent system for all our Varcity users, our VTI will always be free, meaning everyone will always have a real-time free evaluation of their demo trading skills.  Each user will always know, on each closed position, how their trading skills are developing and how they stack up to all other demo traders on the Varcity Network.
                        </p>

                        {/*<p className='fs-16 fw-300 greyclr mt-3'>Great traders are made is more than a motto - we believe in this principle so much that we partner with
                            <a href='https://varcity.io/partners'>
                                    <span className='greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}> like-minded brands and companies </span> 
                                    </a>
                                    that help our users achieve their financial goals. Our goal is to add more partnerships that build upon the skills learned in demo trading towrds a path to success in the markets.</p>
                        <p className='fs-16 fw-300 greyclr mt-3'> <span className='fw-500'> <b>Please note:</b></span>  the VTI rank and metrics are not predictive of performance in live trading.  The VTI is solely intended for educational purposes on Varcity’s trading simulator. More information can be found in our

                            <Link to="/termCondition">
                                <span className='greyclr fw-500' style={{ borderBottom: "1.5px solid #0A4550" }}> <b>Terms and Conditions.</b></span>
                            </Link>
                        </p>

                    </div>
                </div>
           </Container>*/}

            {/*<div className="container-fluid pt-lg-5 mt-5">
                <LightGreenVarcitybanner/>
            </div>*/}

            <Container>
                {/*<div className="row mb-5" style={{ marginTop: "100px" }}>
                    <div className="col-md-6" >
                        <img src={home2Asset} width="100%" height="450px" className='height300 platformLiceningImg' alt="" />
                    </div>
                    <div className="col-md-6 ps-lg-5">
                        <h1 className='fs-42 fw-500 greyclr mt-5  mb-2'>Varcity Platform Licensing</h1>

                        <p className='fs-16 fw-500 greyclr mt-3'>
                            Contact us at <b>licensing@varcity.io</b> to bring our enterprise-grade, fully automated trading simulator to your classroom.  Compete with classmates for the highest VTI score!  Educators - load up your learning materials and create a virtual trading simulator just for your class or school.
                        </p>
                        <p className='fs-16 fw-500 greyclr mt-3'>
                            Varcity also offers bespoke trading platform solutions for financial institutions, brokerages and other financial services.
                        </p>
                    </div>

                </div>*/}
            </Container>
        </>
    )
}

export default Company
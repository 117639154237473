import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { tickCircleAsset } from '../assets';
import SaveButton from '../components/Button/Button'
import SignUp from '../components/signUpComponent/SignUp';

const TemporarySignUp = () => {

    // const [data, setData] = useState([]);
    // const [validateEmail, setValidateEmail] = useState([]);
    // const [loading, setLoading] = useState();



    // const onSubmit = (() => {
    //     validationHandler();
    // })


    // const validationHandler = () => {
    //     let regex = /^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{2,3})+$/;
    //     if (data.match(regex)) {
    //         saveData();
    //         setValidateEmail("");
    //     }
    //     else {
    //         setValidateEmail("Email is invalid");
    //         return false;
    //     }
    // }
    // const spinner = document.getElementById("spinner");

    // const saveData = async () => {
    //     spinner.removeAttribute('hidden');
    //     const response = await fetch('https://marketing-da.varcity.io/api/marketing/save-marketing-email?email=' + data, {
    //         mode: 'cors',
    //     });
    //     spinner.setAttribute('hidden', '');
    //     const responseData = await response.json();

    //     setLoading(responseData?.message)

    // }

    // useEffect(() => {
    //     saveData();
    // }, [])



    return (
        <>
            <Container>
                <div className="row">
                    <div className="col-md-12 mt-5 mb-5">
                        <h2 className='fs-16 fw-700 greyclr'><b>IN CLOSED BETA</b></h2>

                        <p className='fs-16 fw-300 greyclr mt-5'>
                            Thank you for your interest in Varcity. We are currently in a closed Beta to ensure Varcity.io is at its best, for you!
                            Sign up below to join the waitlist so you can be the first to try Pro on a free 7-day trial.
                        </p>

                        <SignUp />

                        {/* <form >
                            <div className='d-flex gap-2 align-items-center mt-4'>
                                <p className='fs-16 fw-300 greyclr '> Email:</p>
                                <div>
                                    <input type="text" className='input_email ps-2 pe-2' value={data} onChange={(e) => { setData(e.target.value) }} placeholder="Enter a Email" />
                                    <p className='fs-14 fw-700 text-danger'>{validateEmail}</p>
                                </div>
                            </div>

                            <div className='d-flex gap-2 align-items-center mt-4'>
                                <SaveButton label="Sign up" buttonStyle="supportSignUp"
                                    onClick={onSubmit} />
                                <div hidden id="spinner"></div>


                                {loading && !data == '' ?
                                    <img src={tickCircleAsset} alt="" />
                                    : null}

                            </div>
                        </form> */}
                        <p className='fs-16 fw-300 greyclr mb-5 mt-3'>
                            You can follow us on Instagram @varcity.io for sneak peaks and updates during our closed beta! Please email support@varcity.io with any questions.
                        </p>
                    </div>
                </div>
            </Container >
        </>
    )
}

export default TemporarySignUp
import React from 'react'
import "./style.css"
import Button from '../components/Button/Button'
import { backAsset, blankImg, home1Asset, home2Asset, home3Asset, home4Asset, homeHeaderLogo, img1Asset, img2Asset, img3Asset, imgfirstAsset, imgsecondAsset, imgthirdAsset, platformLiceningAsset, varcitylogo, varcityNetwork, OurRoadToLaunch, TradeText, RankText, LearnText, EarnText } from '../assets'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import LightGreenVarcitybanner from '../components/varcityBanner/LightGreenVarcitybanner'
import DocumentMeta from 'react-document-meta';
import EmbededSlider from './EmbededSlider'
const Home = () => {
    const meta = {
        title: 'Varcity Trading Simulator',
        description: ' Varcity is a free trading simulator with real-time market data that helps traders at all levels develop great trading skills through forward testing, quantitative trading metrics and rankings.',
        // canonical: 'http://example.com/path/to/page',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'trading simulator, learn to trade, demo trading, forward testing, forex, crypto, indicies, futures'
            }
        }
    };
    return (
        <>
            < DocumentMeta {...meta} />
            < div className="container-fluid" >

                <div className="row pb-5" style={{ background: "#0A4550" }}>

                    <div className="col-1"></div>
                    <div className="col-md-5 mb-5 headerColumn">
                        <div style={{ marginTop: "80px" }}>
                            
                            <h1 className='headerHeading' style={{marginTop: 20}}>
                                Great traders are <span style={{ fontStyle: "italic" }}>made.</span>
                            </h1>

                            <p className='headerSubTitle'>Varcity is a free trading simulator with real-time market data that helps traders at all levels develop great skills through forward testing, quantitative trading metrics and our Varcity Trading Index<sup className='fs-8 smSup'>TM</sup> (VTI) performance rankings.

                            </p>
                            <p className='headerSubTitle mt-4'> Sign up for free to test your trading strategies!</p>
                        </div>

                        <div className='d-flex gap-4 mt-5 sm-justify-content-center'>
                            <a href="https://trading.varcity.io/sign-up-pro" style={{zIndex:10}}>
                                <Button label="Sign up for free" buttonStyle="signUp" />
                            </a>
                            <a href="https://sites.google.com/varcity.io/varcitysupport/home" style={{zIndex:10}}>
                                <Button label="Learn more" buttonStyle="learnMore" />
                            </a>   
                            {/*<Link to="/platform" style={{zIndex:10}}>
                                <Button label="Learn more" buttonStyle="learnMore" />
                            </Link>*/}
                        </div>
                    </div>

                </div>
                <div className="row" >
                    <div className='col-md-12 headerLogo' style={{pointerEvents: 'none'}}>
                        <div className='homeHeaderImg'></div>
                    </div>
                </div>

            </div >



            <Container>
                <div className="row mt-sm-5" style={{ marginTop: "100px" }}>
                    <div className="col-md-6 mt-lg-5 mt-md-5" >
                        {/* <img src={home1Asset} width="100%" height="450px" alt="" className='height450' /> */}
                        <img src={TradeText} width="100%" height="450px" alt="" className='height450'/>
                    </div>
                    <div className="col-md-6 mt-sm-3 mt-lg-5 mt-md-5">
                        <h1 className='fs-42 fw-500 greyclr mt-lg-5 mt-md-5  mb-2'>Fully automated trading simulator with <span style={{ fontStyle: "italic" }}>real-time data.</span></h1>

                        <p className='fs-16 fw-500 greyclr mt-4'>
                            Simulated trading, often called demo trading, is a zero risk way to learn trading skills and test strategies using simulated virtual funds. 
                        </p>
                        <p className='fs-16 fw-500 greyclr mt-4'>
                            Varcity takes the traditional demo trading philosophy a step further:  we have added crowdsourced performance metrics to show how your demo trading skills and strategies stack up to everyone else on our network.
                        </p>
                        <div className='mt-4 mb-2'>
                            <a href="https://trading.varcity.io/sign-up-pro">
                                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Sign up for free</b> </span>
                            </a>
                            {/*<Link to="platform">
                                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b> </span>
                            </Link>*/}
                        </div>
                    </div>
                </div>
                <div className="row mt-sm-5" style={{ marginTop: "70px" }}>
                    <div className="col-md-6">
                        <h1 className='fs-42 fw-500 greyclr mt-lg-5  mt-md-5 mb-2'>Varcity Trading Index<sup className='fs-12 lgSup'>TM</sup>real-time evaluation.</h1>

                        <p className='fs-16 fw-500 greyclr mt-4'>
                            The Varcity Trading Index<sup className='fs-8 smSup'>TM</sup> (VTI) evaluates your quantitative trading skills in real-time, on every closed position, ranking trading performance across our network.  The VTI identifies great traders and shows you the gap between your skills and top demo traders on our network.
                        </p>
                        <div className='mt-4 mb-2'>
                            <a href="https://trading.varcity.io/sign-up-pro">
                                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Sign up for free</b> </span>
                            </a>
                        {/*<p className='fs-16 fw-500 greyclr mt-4'>
                            To acknowledge your successes and recognize your great trading skills, the VTI issues badges into your trophy case for top scores and milestones. <br />
                        </p>
                        <div className='mt-4 mb-2'>
                            <Link to="platform">
                                <span className='fs-16 fw-500 greyclr mt-4' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b></span>
                            </Link>*/}
                        </div>
                    </div>
                    
                    <div className="col-md-6" >
                        <img src={RankText} width="100%" height="450px" className='height450' alt="" />
                    </div>
                </div>

                {/*</div>
                <div className="row mt-sm-5" style={{ marginTop: "70px" }}>
                    <div className="col-md-6" >
                        <img src={LearnText} width="100%" height="450px" alt="" />
                    </div>
                    <div className="col-md-6">
                        <h1 className='fs-42 fw-500 greyclr mt-4 mb-2'>Varcity Network for support and mentorship.</h1>

                        <p className='fs-16 fw-500 greyclr mt-4'>
                            Your VTI rank, badges and trophy case are part of your Varcity Network profile. Access the network to:
                        </p>
                        <ul className='varcityNetworkPoints'>
                            <li className='mt-1'>Connect and learn from top demo traders with high VTI rankings.</li>
                            <li className='mt-1'> Find other demo traders with similar VTIs to work together on strategies and skills.</li>
                            <li className='mt-1'> Find new social content and accounts to follow! </li>
                        </ul>
                        <p className='fs-16 fw-500 greyclr mt-3'>
                            Link and display your social accounts on the Varcity Network to expand your audience.
                        </p>
                        <div className='mt-4 mb-2'>
                            <Link to="platform">
                                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b></span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row mt-sm-5" style={{ marginTop: "70px" }}>
                    <div className="col-md-6 mt-md-5 mt-lg-5">
                        <h1 className='fs-42 fw-500 greyclr mt-md-5 mt-lg-5 mb-2'>Partnerships</h1>

                        <p className='fs-16 fw-500 greyclr mt-4'>
                        Varcity partners with brands and companies that help our users achieve their financial goals. We combine our trading simulator, VTI and quantitative performance rankings to create dynamic collaborations and opportunities.
                        </p>

                        <div className='mt-4 mb-2'>
                            <Link to="partners">
                                <span className='fs-16 fw-500 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Read more</b></span>
                            </Link>
                        </div>
                    </div>
                    <div className="col-md-6" >
                        <img src={EarnText} width="100%" height="450px" className='height300' alt="" />
                    </div>

                </div>*/}

            </Container>

            {/*<div className="container-fluid mt-5">
                <LightGreenVarcitybanner />
            </div>*/}

            {/* <Container>
                <div className="row mb-5 mt-5 pt-lg-3">
                    <div className="col-md-12">
                        <div className='d-flex align-items-center'>
                            <div>
                                <p className='fs-42 fw-500 greyclr' style={{ fontFamily: 'Public Sans' }}><b>Instagram</b></p>
                                <p className='fs-14 fw-400 greyclr'><b>@varcity.io</b></p>
                            </div>
                            <div className='ms-auto pe-5'>
                                <img src={backAsset} alt="" width="44px" height="44px" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div>
                            <img src={imgfirstAsset} alt="" width="100%" height="450px" className='height450' />
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div>
                            <img src={imgsecondAsset} alt="" width="100%" height="450px" className='height450' />
                        </div>
                    </div>
                    <div className="col-md-4 mt-5">
                        <div>
                            <img src={imgthirdAsset} alt="" width="100%" height="450px" className='height450' />
                        </div>
                    </div>
                </div>
            </Container> */}

            {/*<div className='embededSlider' >
                <EmbededSlider/>
            </div>*/}


            {/* <Container>
                <div className="row mb-5 pb-4 mt-3">
                    <div className="col-md-6 mt-lg-5">
                        <img src={OurRoadToLaunch} alt="" width="100%" height="280px" />
                    </div>
                    <div className="col-md-6 mt-lg-5 ps-lg-5 pt-md-5 pt-lg-0">
                        <div>
                            <h1 className='fs-42 fw-500 greyclr'>Our road to launch!</h1>

                            <p className='fs-16 fw-300 greyclr mt-4'>
                                In 2022, we set out on a goal: to create a suite of finantial tools that give everyone access to the capital markets.  And, we had to do it in a way that was inclusive, transparent and ethical. We brought our vision and our demo to Web Summit in Portugal as an ALPHA startup in November 2022, where we were thrilled to receive a fantastic response to the Varcity Trading Index<sup className='fs-8 smSup'>TM</sup>, our transparent approach to financial literacy and of course, our free tools for all.
                                <p className='fs-16 fw-300 greyclr mt-3'>
                                    More on our journey to launch on
                                    <a href="https://www.instagram.com/varcity.io" style={{ color: "#0B4550" }}>
                                        <span className='grey fw-500' style={{ borderBottom: "1.5px solid #0A4550" }}>
                                            <b> Instagram </b>
                                        </span>
                                    </a>
                                   and read about our principled approach on
                                    <a href="https://capfi.medium.com/better-together-e0b9ed773cc8" style={{ color: "#0B4550" }}>
                                        <span className='grey fw-500' style={{ borderBottom: "1.5px solid #0A4550" }}>
                                            <b> Medium. </b>
                                        </span>
                                    </a>
                                </p>

                            </p>
                        </div>
                    </div>
                </div>
        </Container> */}


        </>
    )
}

export default Home

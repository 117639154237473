import React, { useState } from 'react'
import { Button, Container, Form, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import "./Navbar.css"
import { brandLogo } from '../../assets';
import { Link, NavLink } from 'react-router-dom';
import SaveButton from '../Button/Button';


const NavbarComponent = () => {
    const [expanded, setExpanded] = useState('');

    return (
        <>
            <Navbar collapseOnSelect expand="lg" variant="dark" expanded={expanded} >
                <Container fluid className='gap-4 nav-padding' style={{ padding: "0px 60px", background: "#0B4550", zIndex: "12" }}>
                    <div className='d-flex justify-content-between align-items-center collapseWidth'>
                        <Link to="/">
                            <Navbar.Brand className='me-0'>
                                <img src={brandLogo} alt="" width="72px" height="55.57px" />
                            </Navbar.Brand>
                        </Link>
                        <a href="https://trading.varcity.io/sign-up-pro" className='desktopScreenHide'>
                            <SaveButton label="Sign up" buttonStyle="navsignUp" />
                        </a>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(prev => prev === '' ? 'expanded' : '')} />
                    </div>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto gap-4 p-0">

                             {/* <NavLink to="platform" onClick={() => setExpanded(false)}>
                                Platform
                            </NavLink>*/}
                            {/*<NavLink to="pricing" onClick={() => setExpanded(false)}>
                                Pricing
                            </NavLink>/}
                            <NavLink to="company" onClick={() => setExpanded(false)}>
                                Company
                            </NavLink>
                            {/*<NavLink to="careers" onClick={() => setExpanded(false)}>
                                Careers
                            </NavLink>*/}
                            <a href="https://sites.google.com/varcity.io/varcitysupport/home" onClick={() => setExpanded(false)}>
                                Support
                            </a>
                            <NavLink to="contactUs" onClick={() => setExpanded(false)}>
                                Contact
                            </NavLink>
                            {/*<NavLink to="partners" onClick={() => setExpanded(false)}>
                                Partners
                            </NavLink>*/}

                        </Nav>
                        <Nav className=''>
                            <div className='d-flex gap-4'>
                                <a href="https://trading.varcity.io/login">
                                    <SaveButton label="Log in" buttonStyle="navLogIn" />
                                </a>
                                <a href="https://trading.varcity.io/sign-up-pro" className='mobileScreenHide'>
                                    <SaveButton label="Sign up" buttonStyle="navsignUp" />
                                </a>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default NavbarComponent

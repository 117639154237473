import React from "react";
import {useLocation} from 'react-router-dom'

const SignupSuccess = () => {
    const location= useLocation()
  return (
    <>
      {location?.state?.email ? (
        <div className="signupWrapper">
          <div className="center">
            <div className="signupSuccessCard p-4">
              <h5>Sign up successful!</h5>
              <p>A verification link has been set to:</p>
              <p className='fw-bolder'>{location?.state?.email}</p>
              <p>
                Don't forget to check your Spam/Junk foler if you don't see
                Varcity's verificatione email in your inbox
              </p>
              <a href="https://trading.varcity.io/login">Click here to login</a>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SignupSuccess;

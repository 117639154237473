import React, { useState } from "react";
import Button from "../../components/Button/Button";
import { Laptop2, mainChart, brandLogo, brandLogoCard } from "../../assets";
import { useNavigate } from "react-router-dom";

import DocumentMeta from "react-document-meta";
import { tradingAccountsPointForLandingPage1 } from "../../config/constants";
import { signUp } from "../../config/api";
import "./styles.css";

const LandingPage1 = () => {
  const meta = {
    title: "Varcity Trading Simulator",
    description:
      " Varcity is a trading simulator with real-time market data that helps traders at all levels develop great trading skills through forward testing, quantitative trading metrics and rankings.",
    // canonical: 'http://example.com/path/to/page',
    meta: {
      charset: "utf-8",
      name: {
        keywords:
          "trading simulator, learn to trade, demo trading, forward testing, forex, crypto, indicies, futures",
      },
    },
  };
  const navigate = useNavigate();

  const [headerFormData, setHeaderForm] = useState({ login: "", password: "" });
  const [cardFormData, setCardForm] = useState({ login: "", password: "" });

  const [error, setError] = useState({
    headerForm: { login: "", password: "" },
    cardForm: { login: "", password: "" },
  });
  const [showPassword, setShowPassword] = useState(false);
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const handleChange = (e, formType = "") => {
    if (e.target.name === "login") {
      if (!isValidEmail(e.target.value) && e.target.value) {
        setError({
          ...error,
          [formType]: {
            login: "Please enter a valid email (e.g name@domain.com).",
          },
        });
      } else {
        setError({ ...error, [formType]: "" });
      }
    }
    if (formType === "headerForm")
      setHeaderForm({ ...headerFormData, [e.target.name]: e.target.value });
    if (formType === "cardForm")
      setCardForm({ ...cardFormData, [e.target.name]: e.target.value });
  };
  const handleSignUp = (formType) => {
    if (formType === "headerForm") {
      if (!headerFormData?.login && !headerFormData?.password) {
        setError({
          ...error,
          [formType]: {
            login: "Email is a required field",
            password: "Password is a required field",
          },
        });
        return;
      }

      if (!headerFormData?.login) {
        setError({
          ...error,
          [formType]: { login: "Email is a required field" },
        });
        return;
      }
      if (!headerFormData?.password) {
        setError({
          ...error,
          [formType]: { password: "Password is a required field" },
        });
        return;
      }
      if (!headerFormData?.password && !isValidEmail(headerFormData?.login)) {
        setError({
          ...error,
          [formType]: {
            login: "Please enter a valid email (e.g name@domain.com).",
            password: "Password is a required field",
          },
        });
        return;
      }
      if (headerFormData?.password && isValidEmail(headerFormData?.login)) {
        signUp({ ...headerFormData, lpCode: "learn-to-trade-for-free-1" })
          .then((res) => {
            if (res?.data?.success) {
              navigate("/signupSuccess", {
                state: { email: headerFormData?.login },
              });
            }else{
              setError({
                ...error,
                [formType]:{
                  login: res?.data?.message,
                }
              })
            }
          })
          .catch(console.log);
      }
    }
    if (formType === "cardForm") {
      if (!cardFormData?.login && !cardFormData?.password) {
        setError({
          ...error,
          [formType]: {
            login: "Email is a required field",
            password: "Password is a required field",
          },
        });
        return;
      }
      if (!cardFormData?.password && !isValidEmail(cardFormData?.login)) {
        setError({
          ...error,
          [formType]: {
            login: "Please enter a valid email (e.g name@domain.com).",
            password: "Password is a required field",
          },
        });
        return;
      }
      if (!cardFormData?.login) {
        setError({
          ...error,
          [formType]: { login: "Email is a required field" },
        });
        return;
      }

      if (!cardFormData?.password) {
        setError({
          ...error,
          [formType]: { password: "Password is a required field" },
        });
        return;
      }
      if (cardFormData?.password && isValidEmail(cardFormData?.login)) {
        signUp({ ...cardFormData, lpCode: "learn-to-trade-for-free-1" })
          .then(
            (res) =>
              res?.data?.success &&
              navigate("/signupSuccess", {
                state: { email: cardFormData?.login },
              })
          )
          .catch(console.log);
      }
    }
  };

  return (
    <>
      <DocumentMeta {...meta} />
      <div className="container-fluid home-container LP1">
        {/* Home Page header start */}
        <div className="header-wrapper">
          <div className="header-section">
            <div className="header-left">
              <div className="header-content">
                <h1 className="title">Learn to Trade Today!</h1>
                <p className="sub-title">
                  Sign up for a free $100k practice trading account and start
                  your trading journey.
                </p>
              </div>
              <div className="header-form-section">
                <div className="field field-email">
                  <p className="title">Email*</p>
                  <input
                    style={
                      error?.headerForm?.login && !headerFormData?.login
                        ? { border: "1px solid rgb(255 29 29)" }
                        : {}
                    }
                    type="email"
                    id="email"
                    name="login"
                    value={headerFormData?.login}
                    onChange={(e) => handleChange(e, "headerForm")}
                  />
                  {error?.headerForm?.login && (
                    <p style={{ color: "rgb(255 29 29)" }}>
                      {error?.headerForm?.login}
                    </p>
                  )}
                </div>
                <div className="field field-password">
                  <p className="title">Password*</p>
                  <input
                    style={
                      error?.headerForm?.password && !headerFormData?.password
                        ? { border: "1px solid rgb(255 29 29)" }
                        : {}
                    }
                    type="password"
                    id="password"
                    name="password"
                    value={headerFormData?.password}
                    onChange={(e) => handleChange(e, "headerForm")}
                  />
                  {error?.headerForm?.password && (
                    <p style={{ color: "rgb(255 29 29)" }}>
                      {error?.headerForm?.password}
                    </p>
                  )}
                </div>
                <div className="info">
                  <p>
                    By signing up for a Varcity free account, I confirm that I
                    am 18 years of age or older, and agree to Varcity’s
                    <u> Terms and Conditions</u> & <u>Privacy Policy</u>.
                  </p>
                </div>
                <div className="btn-signup">
                  <Button
                    label="Sign up for free!"
                    buttonStyle="signUp"
                    onClick={() => handleSignUp("headerForm")}
                  />
                </div>
              </div>
            </div>
            <div className="header-right">
              <div className="image">
                <img src={mainChart} alt="header-mobile"></img>
              </div>
            </div>
          </div>
        </div>
        {/* Home Page header END */}
        {/* Best learning Platforms  Start*/}
        <div className="platheader-form-section">
          <div className="headline">
            <h3>Best Platform For Learning</h3>
          </div>
          <div className="detail-cards">
            <div className="card">
              <div className="title">
                <h3>Real-time</h3>
                <h6> market data</h6>
              </div>
              <div className="content">
                <p>
                  Unlike other simulators, Varcity has real-time lightning fast
                  market data.
                </p>
              </div>
              <div className="bottom-logo">
                <img src={brandLogo} alt="logo"></img>
              </div>
            </div>{" "}
            <div className="card">
              <div className="title">
                <h3>350+</h3>
                <h6>trading instruments</h6>
              </div>
              <div className="content">
                <p>
                  Don’t limit yourself to just one asset class, trade them all! 
                  Trade Forex, Crypto, Equities, Futures and Indices.
                </p>
              </div>
              <div className="bottom-logo">
                <img src={brandLogo} alt="logo"></img>
              </div>
            </div>{" "}
            <div className="card">
              <div className="title">
                <h3>Get instant </h3>
                <h6>performance metrics</h6>
              </div>
              <div className="content">
                <p>
                  Get instant performance metrics.All your trades get ranked
                  with the Varcity Trading Index™.  Collect badges and climb the
                  ranks!
                </p>
              </div>
              <div className="bottom-logo">
                <img src={brandLogo} alt="logo"></img>
              </div>
            </div>
          </div>
        </div>
        {/* Best learning Platforms End  */}
        <div className="demo-detail-section">
          <div className="headline-wrapper">
            <div className="headline">
              <p>Sign up for a free account!</p>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="left-section">
              <h1>Free Demo Trading Account Includes:</h1>
              <div className="detail-list">
                <ul>
                  {tradingAccountsPointForLandingPage1.map((item) => {
                    return <li>{item}</li>;
                  })}
                </ul>
              </div>
            </div>
            <div className="right-section">
              <img src={Laptop2} alt="Laptop"></img>
            </div>
          </div>
        </div>
        <div className="signup-section">
          <div className="left-section">
            <div className="content">
              <p>
                Sign u<span>p and join Varcity!</span>
              </p>
            </div>
          </div>
          <div className="right-section">
            <div className="signup-form-section">
              <div className="card-logo">
                <img src={brandLogoCard} alt="logo"></img>
              </div>
              <h3>Sign up for free!</h3>
              <div className="fields-info">
                <div className="field field-email">
                  <p className="title">Email*</p>
                  <input
                    style={
                      error?.cardForm?.login && !cardFormData?.login
                        ? { border: "1px solid rgb(255 29 29)" }
                        : {}
                    }
                    type="email"
                    id="email"
                    name="login"
                    value={cardFormData?.login}
                    onChange={(e) => handleChange(e, "cardForm")}
                  />
                  {error?.cardForm?.login && (
                    <p style={{ color: "rgb(255 29 29)" }}>{error?.cardForm?.login}</p>
                  )}
                </div>
                <div className="field field-password">
                  <div className="tooltips">
                    <p>Password*</p>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        cardFormData?.password && setShowPassword(!showPassword)
                      }
                    >
                      {!showPassword ? "Show" : "Hide"}
                    </p>
                  </div>
                  <input
                    style={
                      error?.cardForm?.password && !cardFormData?.password
                        ? { border: "1px solid rgb(255 29 29)" }
                        : {}
                    }
                    type={!showPassword ? "password" : "text"}
                    id="password"
                    name="password"
                    value={cardFormData?.password}
                    onChange={(e) => handleChange(e, "cardForm")}
                  />
                  {error?.cardForm?.password && (
                    <p style={{ color: "rgb(255 29 29)" }}>
                      {error?.cardForm?.password}
                    </p>
                  )}
                </div>
                <div className="info">
                  <p>
                    By signing up for a Varcity free account, I confirm that I
                    am 18 years of age or older, and agree to Varcity’s
                    <u> Terms and Conditions</u> & <u>Privacy Policy</u>.
                  </p>
                </div>
                <div className="btn-signup">
                  <Button
                    label="Sign up for free!"
                    buttonStyle="signUp"
                    onClick={() => handleSignUp("cardForm")}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="learn-more-section">
          <div className="heading">
            <h1>Learn more:</h1>
          </div>
          <div className="links">
            <div className="link-btn">Visit our full website at varcty.io</div>
            <div className="link-btn">
              Download the Varcity iOS app in the App Store
            </div>
            <div className="link-btn">
              Download the Varcity Android app in Google Play
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage1;

import React from 'react'
import { Link } from 'react-router-dom'
import { varcitylogo } from '../../assets'
import "../../pages/style.css"

const LightGreenVarcitybanner = () => {
    return (
        <>
            <div className="row" style={{ background: "#C5F9CA", paddingTop: "100px", paddingBottom: "50px" }}>
                <div className="col-md-12">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-lg-5 col-md-4">
                            <div className='d-flex justify-content-end align-items-center sm-justify-content-center'>
                                <h1 className='bannerHeading pe-lg-5 sm-text-center'>
                                    <span className='fw-300' style={{ fontStyle: "italic", fontFamily: "auto" }}> Sign u</span>p and <br /> join Varcity!
                                </h1>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-8">
                            <div className="row px-sm-3">
                                <div className="col-md-6 mt-sm-5">
                                    <div className='bg-white br-14 pt-4 pb-4 h-100'>
                                        <div className='d-flex flex-column justify-content-center align-items-center text-center gap-4 mt-3'>
                                            <div>
                                                <p className='fs-24 fw-600 greyclr'><b>Basic Account</b></p>
                                                <p className='fs-24 fw-300 greyclr'>Free</p>
                                            </div>
                                            <a href="https://trading.varcity.io/sign-up-pro">
                                                <div className='ps-3 pe-3 pt-2 pb-2' style={{ background: "#DAFD5D", borderRadius: "25px" }}>
                                                    <p className='fs-14 fw-500 greyclr'>Sign up for a free Basic Account</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 mt-sm-5">
                                    <div style={{ position: "relative", left: "26px", top: "-40px" }}>
                                        <div className='rounded-circle d-flex justify-content-center align-items-center' style={{ background: "#0A4550", height: "85px", width: "85px", position: "absolute", right: "0" }}>
                                            <img src={varcitylogo} width="61.31px" height="44.23px" alt="" />
                                        </div>
                                    </div>
                                    <div className='bg-white br-14 pt-4 pb-4 h-100'>
                                        <div className='d-flex flex-column justify-content-center align-items-center text-center gap-4 mt-3'>
                                            <div>
                                                <p className='fs-24 fw-600 greyclr'><b>Pro Account</b></p>
                                                <p className='fs-24 fw-300 greyclr'>7-Day Free Trial</p>
                                            </div>
                                            <a href="https://trading.varcity.io/sign-up-pro">
                                                <div className='ps-3 pe-3 pt-2 pb-2' style={{ background: "#DAFD5D", borderRadius: "25px" }}>
                                                    <p className='fs-14 fw-500 greyclr'>Sign up for a free Pro trial</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className='d-flex justify-content-center mt-5'>
                                        <Link to="/pricing">
                                            <p className='fs-14 fw-400 greyclr' style={{ borderBottom: "1.5px solid #0A4550" }}><b>Compare subscription plans</b></p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>


            </div>
        </>
    )
}

export default LightGreenVarcitybanner
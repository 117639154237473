import React from 'react'
import { Accordion, Container } from 'react-bootstrap'
import "./style.css";
import { affiliateAsset, checkmarkAsset, varcitydarklogo, varcitylogo } from '../assets'
import { Link } from 'react-router-dom';
import LightGreenVarcityBanner from '../components/varcityBanner/LightGreenVarcitybanner'
import DocumentMeta from 'react-document-meta';
const Partners = () => {

    const meta = {
        title: 'Partners',
        description: ' Varcity partners with brands and companies that work towards financial literacy, transparency and accessibility to the financial markets.',
        meta: {
            charset: 'utf-8',
            name: {
                keywords: 'Partnerships, forex, crypto, futures'
            }
        }
    };
    return (
        <>
            < DocumentMeta {...meta} />
            <div className="container-fluid">
                <div className="row md-pb-7 md-pt-9" style={{ background: "#0A4550", paddingTop: "100px", paddingBottom: '80px' }}>
                    <div className="col-1"></div>
                    <div className="col-md-5 ps-5 ps-sm-0 mb-5 headerColumn">
                        <div>
                            <h1 className='headerHeading clubHeading' style={{ fontSize: "80px" }}>
                                Varcity Partnerships
                            </h1>
                            <p className='headerSubTitle mt-4 clubSubHeading' style={{ width: "90%" }}>In line with our values, Varcity partners with brands and companies that work towards financial literacy, transparency and accessibility to the financial markets. Through our Partners, we can combine Varcity's real-time trading simulator, VTI and quantitative performance rankings with other great initiatives that help our users acheive their financial goals.</p> 
                        </div>
                    </div>

                </div>
                <div className="row" >
                    <div className='col-md-12 headerLogo'>
                        <div className='varcityclubHeaderImg'></div>
                    </div>
                </div>

                <div className="row justify-content-center" style={{ background: "#DAFD5D" }}>
                
    </div>

    </div>
            <Container>
                <div className="row">
                    <div className="col-md-12 mt-5">
                        <h1 className='fs-42 fw-500 greyclr text-center'>Varcity x College </h1>
                    </div>
                    <div className="col-md-6 mt-5">
                        <p className='fs-16 fw-300 greyclr '>
                            Varcity partners with Colleges and Universities to host trading competitions and bolster in-class learning, complete with weekly badges and Varcity swag for the winners!  Contact licensing@varcity.io to start teaching your classroom how to trade!
                        </p>
                    </div>
                </div>
            </Container>

            <Container fluid className='mt-5'>
                <LightGreenVarcityBanner />
            </Container>

            <Container>

                <div className="row align-items-center mb-5" style={{ marginTop: "70px" }}>
                    <div className="col-md-6">
                        <h1 className='fs-42 fw-500 greyclr mt-2 mb-2'>Varcity’s Referral Program</h1>

                        <p className='fs-16 fw-500 greyclr mt-3'>
                            Help spread our message of inclusivity, transparency and fairness for all those wanting to learn how to trade, on their path towards becoming a great trader.
                        </p>
                        <p className='fs-16 fw-500 greyclr mt-3'>
                            For a limited time, Varcity's referral program offers 30% lifetime commissions on all Varcity Pro subscriptions purchased through your referrals. Your unique referral code is automatically generated and included in your Welcome email!
                        </p>
                        <a href="https://sites.google.com/varcity.io/varcitysupport/referral-program">
                            <p className='fs-16 fw-500 greyclr mt-3' ><span style={{ borderBottom: "1px solid #0A4550" }}><b>Read more</b> </span> </p>
                        </a>
                    </div>
                 
                </div>

            </Container>
        </>
    )
}

export default Partners